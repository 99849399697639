
div {
  font-family: "Inter";
}

html, body {
  margin: 0;
  padding: 0;
}

pre {
  display: flex;
  margin: 0px;
  padding: 0px !important;
  white-space: pre;
}

code {
  font-family: "Source Code Pro";
}

a {
  text-decoration: none;
  padding: 0px;
  margin: 0px;
}

#nav-icon3 {
  width: 36px;
  height: 36px;
  position: relative;
  z-index: 1200;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  transform: scale(0.7);
  @media (min-width: 900px) {
    transform: scale(0.8);
  }
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #464C54;
  border-radius: 10px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

#nav-icon3.open span {
  background-color: #ffffff;
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 12px;
}

#nav-icon3 span:nth-child(4) {
  top: 24px;
}

#nav-icon3.open span:nth-child(1) {
  top: 0px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

#nav-icon3.open span:nth-child(4) {
  top: 24px;
  width: 0%;
  left: 50%;
}
